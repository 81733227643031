@import "https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@400&display=swap";
@font-face {
  font-family: NM;
  src: url("nm-regular.2c116a6e.woff2") format("woff2"), url("nm-regular.63022cae.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

:root {
  --background-color: black;
  --text-color: white;
  --heading-font-stack: "NM";
  --body-font-stack: "NM", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-size: clamp(14px, 2.6vh, 60px);
  --accent-color: white;
}

* {
  box-sizing: border-box;
}

html, body {
  font-size: var(--font-size);
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--body-font-stack);
  touch-action: none;
}

.bg {
  opacity: .5;
  z-index: 0;
  background-image: url("peas.41c8ead1.gif");
  background-position: 65%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

.container {
  height: 100vh;
  scroll-snap-type: y mandatory;
  z-index: 10;
  position: relative;
  overflow-y: scroll;
}

section {
  height: 100vh;
  scroll-snap-align: start;
  padding: 20px;
}

.content {
  font-variant-ligatures: common-ligatures;
  font-feature-settings: "liga";
  height: 100%;
  max-width: 640px;
  font-weight: 400;
  line-height: 1.12;
}

@media (width <= 520px) {
  .content {
    padding: 4%;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font-stack);
  font-size: var(--font-size);
  margin-top: 0;
  font-weight: normal;
}

.intro {
  opacity: 0;
  font-size: 1.6em;
}

@media (width <= 620px) {
  .intro {
    font-size: 1.8em;
  }
}

.statement {
  font-size: 1.2rem;
}

p {
  margin: 0 0 2rem;
}

.no-margin {
  margin: 0;
}

a {
  color: var(--accent-color);
  margin: 0;
  padding: 0;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: underline;
}

.work a, .contacts a {
  color: var(--accent-color);
  cursor: pointer;
  margin-left: -.5rem;
  padding: .05rem .5rem;
  text-decoration: none;
}

.work a:hover, .contacts a:hover {
  color: #000;
  background: var(--accent-color);
}

.work, .contacts {
  margin-top: .5rem;
  padding: 0;
  list-style-type: none;
  display: block;
}

.work_item, .contact_item {
  margin-right: 1rem;
  line-height: 1.4;
  display: inline-block;
}

.contacts {
  margin-top: auto;
  transition: all .1s ease-in-out;
}

.world__container {
  width: clamp(100px, 10vw, 300px);
  height: clamp(100px, 10vw, 300px);
  border-radius: 50%;
  margin: 3rem;
  overflow: hidden;
}

.world {
  width: 100%;
  height: 100%;
  border: .5px solid #000;
  border-radius: 50%;
  overflow: hidden;
}

.world__image {
  height: 100%;
}

.one, .two {
  background-color: #000;
}

.three {
  background-color: #0000;
}

.planet_first_content {
  width: 40vw;
  height: 20vw;
  text-transform: uppercase;
  color: #fff;
  z-index: 20;
  border: .5px solid #fff;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  font-size: 4rem;
  transition: all .2s;
  display: flex;
  position: absolute;
}

.capabilities {
  margin: 0 0 2rem;
  padding: 0;
}

.capability_item {
  margin-bottom: .8rem;
  list-style-type: none;
}

/*# sourceMappingURL=index.ba50f662.css.map */
