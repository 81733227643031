@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@400&display=swap');
@font-face {
  font-family: 'NM';
  src: url('../assets/fonts/nm-regular.woff2') format('woff2'),
    url('../assets/fonts/nm-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  --background-color: black;
  --text-color: white;
  --heading-font-stack: 'NM';
  --body-font-stack: 'NM', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-size: clamp(14px, 2.6vh, 60px);
  --accent-color: white;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: var(--font-size);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--body-font-stack);
  touch-action: none;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  background-image: url(../assets/images/peas.gif);
  background-position: 65%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.container {
  position: relative;
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
  z-index: 10;
}

section {
  height: 100vh;
  scroll-snap-align: start;
  padding: 20px;
}

.content {
  font-variant-ligatures: common-ligatures;
  font-feature-settings: 'liga';
  font-weight: 400;
  line-height: 1.12;
  height: 100%;
  max-width: 640px;
}

@media (max-width: 520px) {
  .content {
    padding: 4%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font-stack);
  font-size: var(--font-size);
  font-weight: normal;
  margin-top: 0;
}

.intro {
  font-size: 1.6em;
  opacity: 0;
}

@media (max-width: 620px) {
  .intro {
    font-size: 1.8em;
  }
}

.statement {
  font-size: 1.2rem;
}
p {
  margin: 0 0 2rem;
}

.no-margin {
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--accent-color);
  padding: 0;
  margin: 0;
}

a:hover {
  color: white;
  text-decoration: underline;
}

.work a,
.contacts a {
  color: var(--accent-color);
  text-decoration: none;
  padding: 0.05rem 0.5rem;
  margin-left: -0.5rem;
  cursor: pointer;
}

.work a:hover,
.contacts a:hover {
  color: black;
  background: var(--accent-color);
}

.work,
.contacts {
  display: block;
  list-style-type: none;
  margin-top: 0.5rem;
  padding: 0;
}

.work_item,
.contact_item {
  display: inline-block;
  margin-right: 1rem;
  line-height: 1.4;
  /* font-size: 2.6rem; */
}

.contacts {
  margin-top: auto;
  transition: all 0.1s ease-in-out;
}

@media (max-width: 520px) {
}

.world__container {
  width: clamp(100px, 10vw, 300px);
  height: clamp(100px, 10vw, 300px);
  margin: 3rem;
  border-radius: 50%;
  overflow: hidden;
}

.world {
  width: 100%;
  height: 100%;

  border-radius: 50%;
  overflow: hidden;
  border: 0.5px solid black;
}

.world__image {
  height: 100%;
}

.one,
.two {
  background-color: black;
}

.three {
  background-color: transparent;
}

.tooltip__container {
}
.planet_first_content {
  position: absolute;
  width: 40vw;
  height: 20vw;
  font-size: 4rem;
  padding: 3rem;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  transition: all 0.2s;
  /* background-color: black; */
  border: 0.5px solid white;
}

.capabilities {
  margin: 0 0 2rem 0;
  padding: 0;
}

.capability_item {
  margin-bottom: 0.8rem;
  list-style-type: none;
}
